// import mutations from "./mutations.js";
// import actions from "./actions.js";
import getters from "./getters.js";
import prof_img from "../../../../public/Hasan.png";

export default {
  namespaced: true,
  state() {
    return {
      personalInfo: {
        profileImage: prof_img,
        name: "Hasan YAMEEN",
        title: "Front End Developer",
      },

      links: [
        {
          id: "1",
          name: "linkedin",
          url: "https://www.linkedin.com/in/hasanyameen13/",
          icon: "fa-brands fa-linkedin",
          brandColor: "#0077b5",
        },
        {
          id: "2",
          name: "github",
          url: "https://github.com/hasan-yamin",
          icon: "fa-brands fa-github",
          brandColor: "#333",
        },
        {
          id: "3",
          name: "instagram",
          url: "https://www.instagram.com/hy__coding/",
          icon: "fa-brands fa-instagram",
          brandColor: "#fd1d1d",
        },
        {
          id: "4",
          name: "whatsapp",
          url: "",
          icon: "fa-brands fa-whatsapp",
          brandColor: "#25d366",
        },
        {
          id: "5",
          name: "mail",
          url: `mailto:info@hasanyameen.com?subject=Request For Offer&body=Hello Mr.Hasan ...`,
          icon: "fa-solid fa-at",
          brandColor: "#0077b5",
        },
      ],
      newsList: [
        {
          id: "1",
          title: "Services page",
          detail: "Services page was added to my personal site",
        },
      ],
      skills: [
        {
          name: "Vue.js",
          icon: "fa-brands fa-vuejs",
          percentage: 90,
        },
        {
          name: "JavaScript",
          icon: "fa-brands fa-square-js",
          percentage: 90,
        },
        {
          name: "TypeScript",
          icon: "",
          percentage: 80,
        },
        {
          name: "HTML-HTML5 ",
          icon: "fa-brands fa-html5",
          percentage: 85,
        },
        {
          name: "CSS3-SCSS",
          icon: "fa-brands fa-sass",
          percentage: 83,
        },
        {
          name: "JQuery",
          icon: "",
          percentage: 75,
        },
        {
          name: "PYTHON",
          icon: "fa-brands fa-python",
          percentage: 90,
        },
        {
          name: "REACT.JS",
          icon: "fa-brands fa-react",
          percentage: 70,
        },
      ],
      otherSkills: [
        "Data Analysis",
        "Spreadsheets",
        "SQL",
        "Data Visualization",
        "Data Cleansing",
        "Exel", 
        "Critical thinking",
        "Problem solving",
        "Teamwork",
        "GitHub",
        "Node js",
        "Java",
        "Mathematics",
        "C++", 
      ],
      summaries: [
        {
          id: 1,
          description:
            "I hold a Bachelor's degree in Communications Engineering from Palestine Technical University and a Master's degree from Kocaeli University in Turkey. My professional focus centers on website programming as a freelancer.",
        },
        {
          id: 2,
          description:
            "Since 2020, I have actively immersed myself in the realm of real estate sales, catering to both local and international markets. This involvement has afforded me the opportunity to enhance my expertise in this field. Currently, I serve as international team Sales Manager at Zeray Construction Company, a prominent entity ranked among the top 10 construction companies in Turkey, a position I have held since April 2022.",
        },
      ],
      // jobs: [
      //   {
      //     id: 1,
      //     link: 'https://hyconsultant.hasanyameen.com/',
      //     title: 'realestate',
      //     company: 'Zeray Insaat',
      //     description: 'explore my website for the most current information regarding real estate and investment opportunities in Turkey.'
      //   }, {
      //     id: 2,
      //     link: 'https://hycoding.hasanyameen.com/',
      //     title: 'programming',
      //     company: 'Freelancer',
      //     description: 'visit my website to peruse my portfolio and receive a proposal for the development of your customized website, enabling you to embark on your entrepreneurial journey.'
      //   }

      // ],
      experiences: [
        {
          id: "1",
          jobTitle: "Frontend Web Developer",
          companyName: "Freelance",
          workPeriod: "Jan 2022 - Present",
          companyAddress: "Kocaeli, Turkey",
          jobDescription:
            "As a Front-End Web Developer, I play a pivotal role in creating the visual elements of websites and web applications to ensure an exceptional user experience. My expertise lies in translating design concepts into interactive, responsive, and functional interfaces. I have a deep understanding of HTML for structuring web content and CSS for styling and layout. Additionally, my proficiency in JavaScript enables me to add dynamic content, interactivity, and complex functionalities to web pages. In my work, I often utilize popular front-end frameworks and libraries such as React.js, and Vue.js. I am skilled in responsive web design principles, ensuring seamless adaptation to various devices and screen sizes. I make use of CSS frameworks like Bootstrap for creating responsive layouts. Version control systems like Git are integral to my collaborative development process, allowing me to track changes and work efficiently with teams. My familiarity with CSS preprocessors like SASS enhances my ability to write maintainable and organized stylesheets. I am adept at using browser developer tools and debugging techniques to identify and resolve front-end issues effectively. In essence, I bring a wealth of knowledge and expertise in HTML, CSS, JavaScript, as well as various frameworks and tools, enabling me to create visually appealing, responsive, and interactive web experiences for users. ",
          skills: [
            "HTML5",
            "CSS3",
            "JavaScript",
            "SASS",
            "TypeScript",
            "ES6",
            "GitHub",
            "Vue.js",
            "jQuery",
            "Bootstrap",
            "React.js",
            "Problem solving",
            "Critical thinking",
            "Mathematics",
            "Teamwork",
          ],
        },
        {
          id: "2",
          jobTitle: "sales manager",
          companyName: "ZERAY INSAAT",
          workPeriod: "Apr 2022 - Present",
          companyAddress: "Kocaeli, Turkey",
          jobDescription:
            "After being promoted to a sales manager, the main task is to guide employees on how to achieve the required goals, make visits to marketers and potential customers outside Turkey, expand the international sales market, follow up on sales, profits and losses, and evaluate reports on the reasons and recommendations necessary to advance the company's interest.After being promoted to a sales manager, the main task is to guide employees on how to achieve the required goals, make visits to marketers and potential customers outside Turkey, expand the international sales market, follow up on sales, profits and losses, and evaluate reports on the reasons and recommendations necessary to advance the company's interest.",
          skills: [
            "Negotiation",
            "Creative Problem Solving",
            "Teamwork",
            "Team Leadership",
            "Time Management ",
            "Team Management",
            "Accurate and effective",
            "Persuasion ",
            "Sales",
            "International Sales",
            "Customer Service",
            "English",
          ],
        },
        {
          id: "3",
          jobTitle: "Sales Consultant",
          companyName: "ZERAY INSAAT",
          workPeriod: "Oct 2021 - Apr 2022",
          companyAddress: "Kocaeli, Turkey",
          jobDescription:
            "Due to the achievement of the required goals, I was promoted to a sales consultant, the additional job is to assist new employees in understanding and mastering the work in addition to providing advice and advice to customers and achieving the company's profits with the largest return through financial accountsDue to the achievement of the required goals, I was promoted to a sales consultant, the additional job is to assist new employees in understanding and mastering the work in addition to providing advice and advice to customers and achieving the company's profits with the largest return through financial accounts.",
          skills: [
            "Negotiation",
            "Teamwork",
            "Accurate and effective",
            "Persuasion",
            "Sales",
            "International Sales",
            "Customer Service",
          ],
        },
        {
          id: "4",
          jobTitle: "Sales Assistant",
          companyName: "ZERAY INSAAT",
          workPeriod: "Feb 2021 - Oct 2021",
          companyAddress: "Kocaeli, Turkey",
          jobDescription:
            "The work system in the company is based on promotions, I started working as a sales assistant, the main task is to achieve sales for existing customers, attract new customers, and communicate with marketers inside and outside TurkeyThe work system in the company is based on promotions, I started working as a sales assistant, the main task is to achieve sales for existing customers, attract new customers, and communicate with marketers inside and outside Turkey",
          skills: ["Persuasion", "Sales", "International Sales"],
        },
        {
          id: "5",
          jobTitle: "Sales Consultant",
          companyName: "the masterkey consultancy",
          workPeriod: "Jan 2020 - Jan 2021",
          companyAddress: "Bursa / Kocaeli, Turkey",
          jobDescription:
            "The field of work in the company is marketing and real estate in Kocaeli and Bursa, in addition to the translation service for clients, marketing Turkish products in the international market and exporting them abroad.The field of work in the company is marketing and real estate in Kocaeli, in addition to the translation service for clients, marketing Turkish products in the international market and exporting them abroad.",
          skills: [
            "Negotiation",
            "Time Management",
            "Accurate and effective",
            "Persuasion",
            "Sales",
            "International Sales",
          ],
        },
        {
          id: "6",
          jobTitle: "Technical support",
          companyName: "Hadara Technologies",
          workPeriod: "Mar 2015 - Aug 2015",
          companyAddress: "RamAllah , Palestine",
          jobDescription:
            "Solving customer's problems like : No internet problems / Slowness problems / interruption problems and configuring new router.",
          skills: [],
        },
      ],
      educations: [
        {
          id: "1",
          year: "2021 Jan ",
          degree: "3.51/ 4.00",
          // department: 'Electronics and Telecommunication Eng.',
          university:
            "Master's degree in Electronics and Telecommunication Eng",
        },
        {
          id: "2",
          year: "2015 Jan",
          degree: " 90.70 / 100.00",
          // department: 'Electronics and Telecommunication Eng.',
          university:
            "Bachelor's degree in Electronics and Telecommunication Eng",
        },
        {
          id: "3",
          year: "2010 Jul",
          degree: "95.50 / 100.00",
          // department: 'Electronics and Telecommunication Eng.',
          university: "Secondary School",
        },
      ],
      languages: [
        {
          id: "1",
          name: "Arabic",
          read: 100,
          write: 100,
          speak: 100,
        },
        {
          id: "2",
          name: "English",
          read: 85,
          write: 85,
          speak: 85,
        },
        {
          id: "3",
          name: "Turkish",
          read: 90,
          write: 90,
          speak: 90,
        },
      ],
    };
  },
  // mutations,
  // actions,
  getters,
};
