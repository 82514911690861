<template>
  <base-spinner v-if="showLoadingDialog"></base-spinner>

  <base-dialog @close="closeDialog" @confifrm="closeDialog" :show="showErrorDialog" title="Error">
    <!-- <p> {{ ErrorMsg }} </p> -->
  </base-dialog>
  <title-style>Offered services</title-style>
  <p class="maxim">Don't be busy, Be productive</p>
  <mover-component />
  <h2>
    We Run All Kinds Of frontend Services for individuals and companies to
    own their personal and official website like.
  </h2>
  <section class="services">
    <services-component />
  </section>
</template>

<script>
import ServicesComponent from '../components/services/ServicesComponent.vue'
export default {
  components: {
    ServicesComponent,
  },
  data() {
    return {
      ErrorMsg: '',
      showErrorDialog: false,
      showLoadingDialog: false, 
    }
  },
  created() {
    this.loadServices();
    // this.saveServices();
  },
  methods: {
    closeDialog() {
      this.showErrorDialog = false
      this.ErrorMsg = ''
    },
    async loadServices() {
      this.showLoadingDialog = true;
      console.log("load  Services from DB ...");
      try {
        await this.$store.dispatch("services/loadServices");
      }
      catch (error) {
        this.showErrorDialog = true
        this.ErrorMsg = error.message || "Something went wrong while loading Services from DB!";
      }
      this.showLoadingDialog = false;
    },
    // async saveServices() {
    //   let url = `https://hasanyameen-com-default-rtdb.firebaseio.com/services.json`;
    //   const response = await fetch(url, {
    //     method: "POST",
    //     body: JSON.stringify(this.servis),
    //   });
    //   const responseData = await response.json();
    //   if (!response.ok) {
    //     console.log(responseData);
    //     const error = new Error(responseData.message || "failed to authenticate");
    //     throw error;
    //   }
    // },
  },
}
</script>

<style scoped lang="scss">
h2 {
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  width: 85%;
  margin: 10px auto;
  text-transform: capitalize;
  text-align: center;

  @media (max-width: 767px) {
    width: 90%;
  }
}

.services {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  padding-bottom: 20px;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 35px;
  display: flex;
  flex-direction: column;
  // @media (max-width: 767px) {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  // }
  @media (max-width: 767px) {
    width: 90%;
  }
}
</style>
