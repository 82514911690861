<template>
  <div class="side-left-container">
    <div class="profile-picture">
      <img :src="getPersonalInfo.profileImage" alt="profile picture">
    </div>

    <h1 class="name">{{ getPersonalInfo.name }}</h1>
    <p class="title">{{ getPersonalInfo.title }}</p>
    <section class="links">
      <div class="link" v-for="link in getLinks" :key="link.id">
        <a :href="link.url" target="_blank">
          <font-awesome-icon :icon="link.icon" :style="{ color: link.brandColor }" />
        </a>
      </div>
    </section>
    <section class="cv">
      <button class="download-btn">
        <span class="folderContainer">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 146 113" height="113" width="146"
            class="fileBack">
            <path fill="url(#paint0_linear_117_4)"
              d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z">
            </path>
            <defs>
              <linearGradient gradientUnits="userSpaceOnUse" y2="95.4804" x2="72.93" y1="0" x1="0"
                id="paint0_linear_117_4">
                <stop stop-color="#8F88C2"></stop>
                <stop stop-color="#5C52A2" offset="1"></stop>
              </linearGradient>
            </defs>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 88 99" height="99" width="88"
            class="filePage">
            <rect fill="url(#paint0_linear_117_6)" height="99" width="88"></rect>
            <defs>
              <linearGradient gradientUnits="userSpaceOnUse" y2="160.5" x2="81" y1="0" x1="0" id="paint0_linear_117_6">
                <stop stop-color="white"></stop>
                <stop stop-color="#686868" offset="1"></stop>
              </linearGradient>
            </defs>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 160 79" height="79" width="160"
            class="fileFront">
            <path fill="url(#paint0_linear_117_5)"
              d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z">
            </path>
            <defs>
              <linearGradient gradientUnits="userSpaceOnUse" y2="82.8317" x2="66.9106" y1="8.71323" x1="38.7619"
                id="paint0_linear_117_5">
                <stop stop-color="#C3BBFF"></stop>
                <stop stop-color="#51469A" offset="1"></stop>
              </linearGradient>
            </defs>
          </svg>
        </span>
        <p class="text">Download CV</p>
      </button>

    </section>
    <new-news />
    <!-- <contact-us /> -->
  </div>

</template>

<script>
import NewNews from './NewNews.vue'
// import contactUs from '../components/ContactUs.vue'
export default {
  components: {
    //  contactUs,
    NewNews,
  },
  computed: {
    getPersonalInfo() {
      return this.$store.getters["home/getPersonalInfo"]
    },
    getLinks() {
      return this.$store.getters["home/getLinks"]
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../scss/vars';

.side-left-container {
  width: 100%;
  border-radius: 10px;

  .profile-picture {
    width: 200px;
    aspect-ratio: 1/1;
    // border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // background-color: $bg-clr-1;
    // border: 2px solid $bg-clr-2;

    img {
      width: 100%;
    }
  }

  .name {
    position: relative;
    z-index: 5;
    width: 100%;
    margin-top: -35px;
    font-size: 25px;
    font-weight: bold;
    line-height: 35px;
    background-color: $bg-clr-2;
    // background-color: $bg-clr-2-2;

  }

  .title {
    width: 100%;
    font-size: 20px;
    font-weight: normal;
    line-height: 35px;
    // background-color: $bg-clr-3;
  }

  .links {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px auto;
    box-shadow: $bg-clr-2 1px 2px 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;

    .link {

      a {
        display: inline-block;
        text-decoration: none;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 25px;

          &:hover {
            cursor: pointer;
            font-size: 30px;
          }
        }
      }
    }
  }

  .cv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .download-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;
      height: 45px;
      border: none;
      padding: 0px 15px;
      border-radius: 5px;
      background-color: rgb(49, 49, 83);
      gap: 10px;
      cursor: pointer;
      transition: all 0.3s;
    }

    .folderContainer {
      width: 40px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }

    .fileBack {
      z-index: 1;
      width: 80%;
      height: auto;
    }

    .filePage {
      width: 50%;
      height: auto;
      position: absolute;
      z-index: 2;
      transition: all 0.3s ease-out;
    }

    .fileFront {
      width: 85%;
      height: auto;
      position: absolute;
      z-index: 3;
      opacity: 0.95;
      transform-origin: bottom;
      transition: all 0.3s ease-out;
    }

    .text {
      color: white;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    .download-btn:hover .filePage {
      transform: translateY(-5px);
    }

    .download-btn:hover {
      background-color: rgb(58, 58, 94);
    }

    .download-btn:hover .fileFront {
      transform: rotateX(30deg);
    }

  }
}
</style>
